<template>
	<p class="mt-5 mb-4">{{ this.$root?.translate('email-addres-not-includes') }}</p>

	<form class="login-registration__form" @submit.prevent="this.registration()">
		<div class="row">
			<div class="col-12 mb-4">
				<input-unit
						v-model="this.modelValue.last_name"
						:options="{
							slug: 'register-last-name',
							name: this.$root?.translate('lastname'),
							placeholder: translate('register-last-name-placeholder'),
							pattern: '[A-Za-záéíóöőúüűÁÉÍÓÖŐÚÜŰ]+',
							realTimeValidation: false,
							required: true,
						}"
				/>
			</div>

			<div class="col-12 mb-4">
				<input-unit
						v-model="this.modelValue.first_name"
						:options="{
							slug: 'register-first-name',
							name: this.$root?.translate('firstname'),
							placeholder: translate('register-first-name-placeholder'),
							pattern: '[A-Za-záéíóöőúüűÁÉÍÓÖŐÚÜŰ]+',
							realTimeValidation: false,
							required: true,
						}"
				/>
			</div>

			<div class="col-12 mb-4">
				<input-unit
						v-model="this.modelValue.telephone"
						:options="{
							slug: 'register-telephone',
							name: translate('mobil'),
							placeholder: translate('mobil-placeholder'),
							mask: '+###############',
							realTimeValidation: false,
							required: true,
							// pattern: '.[0-9]{2} (10|20|30|40|50|60|70) [0-9]{3} [0-9]{4}',
						}"
				/>
			</div>

			<div class="col-12 mb-4" v-if="!isHide">
				<birth-date-selector
						v-model="this.birthDate"
						:options="{
							slug: 'register-birthdate',
							required: true,
							name: this.$root?.translate('birthday'),
						}"/>
			</div>

			<template v-if="this.modelValue.is_register ||  !this.modelValue.advert_id">
				<div class="col-12 mb-4">
					<password-unit
							v-model="this.modelValue.password"
							:options="{
								slug: 'register-password',
								name: this.$root?.translate('password'),
								placeholder: this.$root?.translate('min-8-char'),
								minlength: 8,
								required: true,
							}"
					/>
				</div>

				<div class="col-12 mb-4">
					<email-verified-input
							v-model="this.modelValue.code"
							:email="this.modelValue.email"
							:options="{
									slug: 'register-code',
									name: this.$root?.translate('email-confirm-address'),
									standardText: true,
									placeholder: this.$root?.translate('6-char-code'),
									minlength: 6,
									required: true,
									showLoader: this.isLoading,
								}"
					/>
				</div>
			</template>

			<div class="col-12 mb-4">
				<label for="cv-upload" class="file-input-unit__label">
					<span>{{ this.$root?.translate('cv-upload') }}</span>
					<span class="ms-1 fst-italic fw-normal">({{ this.$root?.translate('option') }})</span>
				</label>
				<file-input-unit
						:options="{
								slug: 'register-cv-upload',
								accept: ['pdf', 'doc', 'docx', 'jpg', 'png', 'jpeg', 'odt', 'odf'],
								placeholder: this.$root?.translate('file-upload'),
								customUploadButton: true,
							}"
						@file-change="this.cvChange($event)"
				>
					<i class="fa-solid fa-upload pe-4"></i>
				</file-input-unit>
			</div>

			<div class="col-12 mb-5" v-if="this.modelValue.advert_id">
				<checkbox-unit
						v-model="this.modelValue.is_register"
						:options="{
							slug: 'register-is-register',
							title: this.$root?.translate('want-register'),
						}"
				/>
			</div>

			<div class="col-12 mb-4 login-registration__form-checkbox">
				<label class="input-unit__label required">{{ this.$root?.translate('accept-term') }}</label>
				<checkbox-unit
						v-model="this.modelValue.policy"
						:options="{
							slug: 'register-gdpr',
							required: true,
							customTitle: true,
							title: `<a href='/privacy' target='_blank' class='fw-normal link'>${this.$root?.translate('in-tos')}</a> <span class='fw-normal'>${this.$root?.translate('in-tos-2')}</span>`,
						}"
				/>
			</div>

			<div class="col-12 mb-5 login-registration__form-checkbox">
				<label class="input-unit__label">{{ this.$root?.translate('newsletter-subscribe') }}</label>
				<checkbox-unit
						v-model="this.modelValue.newsletter"
						:options="{
							slug: 'register-newsletter',
							customTitle: true,
							title: `<span class='fw-normal'>${this.$root?.translate('newsletter-info')}</span>
									<a href='/privacy' target='_blank' class='fw-normal link'>${this.$root?.translate('newsletter-info-1')}</a>
									<span class='fw-normal'>${this.$root?.translate('newsletter-info-2')}</span>`,
						}"
				/>
			</div>
		</div>

		<div class="d-flex flex-column-reverse flex-md-row align-items-center justify-content-md-between">
			<button-unit
					:options="{
						text: this.$root?.translate('back'),
						shape: ButtonUnitShape.ROUNDED,
						textColor: this.primaryColorVar,
						bgColor: '',
						class: 'lg bordered-lg login-registration__secondary-btn',
						disabled: this.isLoading,
					}"
					@click-on-btn="this.goBack()"
			/>
			<button-unit
					:options="{
						text: this?.modelValue?.advert_id ?  this.$root?.translate('advert-apply') : this.$root?.translate('i-register'),
						type: 'submit',
						shape: ButtonUnitShape.ROUNDED,
						bgColor: this.primaryColorVar,
						textColor: this.buttonTextColor,
						class: 'lg mb-4 mb-md-0 login-registration__primary-btn',
						disabled: this.isLoading,
						showLoader: this.isLoading,
					}"
					@click-on-btn="this.registration()"
			/>
		</div>
	</form>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {useAuthStore} from "../../../Stores/Auth/AuthStore";
import {RegistrationModelInterface} from "../../../Interfaces/RegistrationModelInterface";
import {DateClass, DateInterface} from "../../../Interfaces/DateInterface";
import axios from "axios";
import ProfilePageMixin from "../../../Mixins/ProfilePageMixin";
import InputErrorHandler from "../../../Mixins/InputErrorHandler";
import SweetAlertMixin from "../../../Mixins/SweetAlertMixin";
import FileUploadMixin from "../../../Mixins/FileUploadMixin";
import ButtonUnit from "../../Units/ButtonUnit.vue";
import InputUnit from "../../Units/InputUnit.vue";
import BirthDateSelector from "../../Units/BirthDateSelector.vue";
import FileInputUnit from "../../Units/FileInputUnit.vue";
import CheckboxUnit from "../../Units/CheckboxUnit.vue";
import PasswordUnit from "../../Units/PasswordUnit.vue";
import EmailVerifiedInput from "../../Units/EmailVerifiedInput.vue";
import {applyAdvert} from "../../../API/advert.api";
import {useSiteCheckStore} from "@WebApp/Stores/SiteCheck/SiteCheck";
import {SiteCheckInterface} from "@WebApp/Stores/SiteCheck/Interfaces/SiteCheckInterface";

export default defineComponent({
	name: "LoginRegistrationForm",
	inject: ['emitter'],
	components: {EmailVerifiedInput, PasswordUnit, CheckboxUnit, FileInputUnit, BirthDateSelector, InputUnit, ButtonUnit},
	mixins: [ProfilePageMixin, InputErrorHandler, SweetAlertMixin, FileUploadMixin],
	setup() {
		return {
			authStore: useAuthStore(),
			siteStore: useSiteCheckStore()
		}
	},
	props: {
		modelValue: {
			type: Object as PropType<RegistrationModelInterface>,
			required: true,
		},
	},
	emits: ['goBack', 'closeModal'],
	data() {
		return {
			isLoading: false,
			birthDate: new DateClass(),
			cv: '' as string | Blob,
		}
	},
	computed: {
		formKeys(): string[] {
			let formKeysBase = [
				'register-last-name',
				'register-first-name',
				'register-telephone',
				'register-gdpr',
			];

			if (this.registerShow) {
				formKeysBase.push('register-birthdate');
			}

			if (this.modelValue.is_register) {
				return [
					...formKeysBase,
					'register-password',
					'register-code',
				];
			}
			return [...formKeysBase];
		},
		currentSite(): SiteCheckInterface {
			return this.siteStore.currentSite;
		},
		isHide(): boolean {
			return this.currentSite.profile.config.birthday_function_hide;
		}
	},
	watch: {
		birthDate: {
			handler(newValue: DateInterface): void {
				this.modelValue.date_of_birth = Object.values(newValue).join('-');
			},
			deep: true
		},
	},
	methods: {
		reset(): void {
			this.isLoading = false;
			this.birthDate = new DateClass();
			this.cv = '' as string | Blob;
		},
		goBack(): void {
			this.reset();
			this.$emit('goBack');
		},
		registration(): void {
			if (this.hasErrors()) {
				return;
			}

			this.isLoading = true;


			if (!this.modelValue.advert_id) {
				this.modelValue.is_register = true;
			}

			axios.post('/api/register', this.modelValue).then(response => {
				if (response.data.success && response.status === 201) {

					if (this.modelValue.is_register) {
						this.authStore.setToken(response.data.user);
						this.authStore.getMe()
					}

					if (this.cv) {
						this.uploadCv();
					}

					if (this.modelValue.hasOwnProperty('advert_id') && this.modelValue.advert_id !== undefined) {
						this.isLoading = true;
						this.showAdvertLoadingAlert();

						applyAdvert(this.modelValue.advert_id, response.data.email).then(data => {
							if (data) {
								sessionStorage.setItem('lastAppliedAdvertName', data.advert_name);
								window.location.href = `/karrier?utm_event=job_application&utm_advert=${data.index_id}&utm_jobcategory=${data.category}&success`;
							}
						}).catch((error: any) => {
							if (error.status === 409) {
								this.customErrorAlert(this.translate('no-employee-applied-error'), this.translate('no-employee-applied-error'));
							} else if (error.status === 504) {
								this.customErrorAlert(this.translate('ops-error-title'), this.translate('ops-error-title'));
							} else {
								this.customErrorAlert(this.translate('failed-applied-error'), this.translate('failed-applied-error'));
							}
						}).finally(() => {
							this.isLoading = false;
						});
					} else {
						this.reset();
						this.$emit('closeModal');

						if (this.modelValue.is_register) {
							this.$router.push({path: this.getSimpleLocalizeRoute('profile')});
						}
					}

				}
			}).catch((error) => {
				this.customErrorAlert(this.$root?.translate('alert-error-title'), error.response?.data.message);
			}).finally(() => {
				this.isLoading = false;
			});
		},
		cvChange(files: any): void {
			this.cv = files[0];
		},
		uploadCv(): void {
			this.isLoading = true;
			this.userFileUpload(this.cv, 'cv', 'cv', this.modelValue.email).then(() => {
				// TODO: loader megjelenik?
			}).catch((error: any) => {
				if (error.response.status === 422) {
					this.customErrorAlert('Hibás fájl formátum', 'Önéletrajzként csak a következő fájlformátumokat tudod feltölteni: pdf,doc,docx,jpg,png,jpeg,odt,odf');
					return;
				}
				this.errorSaveAlert();
			}).finally(() => this.isLoading = false);
		},
	},
});
</script>

<style scoped>
</style>
