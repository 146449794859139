<template>
	<nav class="layout-header__container" :class="currentSite.sticky_header ? 'layout-header--sticky' : ''">
		<div :class="`container h-100 d-flex flex-column flex-${this.headerBreakpoint}-row justify-content-${this.headerBreakpoint}-between align-items-${this.headerBreakpoint}-center px-0`">
			<div :class="`layout-header__logo px-4 pe-${this.headerBreakpoint}-0`">
				<router-link :to="{ name: 'home' }" class="d-flex align-items-center">
					<img :src="this.siteStore.currentSite?.logoUrl" alt="logo">
				</router-link>
				<i :class="`fa-solid fa-bars d-${this.headerBreakpoint}-none layout-header__menu-btn`" @click="this.toggleMenuLinks()"></i>
			</div>
			<Transition name="slide-fade">
				<div v-if="this.isLinksOpened" :class="`d-flex align-items-${this.headerBreakpoint}-center flex-column flex-${this.headerBreakpoint}-row px-4 ps-${this.headerBreakpoint}-0 layout-header__list`">
					<ul
							v-if="this.siteStore.currentSite?.links && this.siteStore.currentSite?.links?.length > 0"
							:class="`my-4 my-${this.headerBreakpoint}-0 d-flex flex-column flex-${this.headerBreakpoint}-row align-items-${this.headerBreakpoint}-center gap-4 gap-${this.headerBreakpoint}-5`"
					>
						<li class="layout-header__item" v-for="item in this.siteStore.currentSite?.links" @click="this.toggleMenuLinks(item.children && item.children?.length > 0)">
							<layout-header-link :item="item" @click-on-children="this.toggleMenuLinks()"/>
						</li>
						<li class="layout-header__item" v-if="currentUser.hasOwnProperty('email')">
							<layout-header-link
									:item="{
										title: this.$root?.translate('my-profile'),
										link: this.getSimpleLocalizeRoute('profile'),
										isExternalLink: false,
										collapsed: false,
									}"
									@click-on-children="this.toggleMenuLinks()"
							/>
						</li>
					</ul>
					<profile-sidebar-links v-if="this.authStore.isLoggedIn" :is-it-open-by-default="false" :textColor="this.textColor" class="d-lg-none mb-4"/>
					<button-unit
							v-if="this.authStore.isLoggedIn"
							:options="{
								text: this.$root?.translate('general-logout'),
								shape: this.buttonShape,
								bgColor: this.buttonBgColor,
								textColor: this.buttonTextColor,
								class: `mb-4 mb-${this.headerBreakpoint}-0 ms-${this.headerBreakpoint}-5 ${this.siteStore.currentSite?.buttonBgColor === this.siteStore.currentSite?.bgColor ? 'bordered' : ''}`,
							}"
							@clickOnBtn="this.logout()"
					/>
					<button-unit
							v-else-if="this.siteStore.currentSite.login_location === 'header'"
							:options="{
								text: this.$root?.translate('login-or-registration'),
								shape: this.buttonShape,
								bgColor: this.buttonBgColor,
								textColor: this.buttonTextColor,
								class: `mb-4 mb-${this.headerBreakpoint}-0 ms-${this.headerBreakpoint}-5 ${this.buttonBgColor === this.bgColor ? 'bordered' : ''}`,
							}"
							@clickOnBtn="this.openModal()"
					/>
					<language-select
							:class="`mb-4 mb-${this.headerBreakpoint}-0 ms-${this.headerBreakpoint}-3`"
							:textColor="this.textColor"
							:bgColor="this.bgColor"
							:type="this.siteStore.currentSite.language_selector_type"
					/>
				</div>
			</Transition>
		</div>
	</nav>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import LayoutHeaderLink from "./LayoutHeaderLink.vue";
import ScreenWidthChecker from "../../Mixins/ScreenWidthChecker";
import {Breakpoint} from "../../Utils/Breakpoint";
import ButtonUnit from "../Units/ButtonUnit.vue";
import LanguageSelect from "../LanguageSelect.vue";
import ProfileSidebarLinks from "../../Views/ProfilePageModule/Components/ProfileSidebarLinks.vue";
import {LayoutCssVariables} from "../../Utils/LayoutCssVariables";
import {ButtonUnitShape} from "../Units/Interfaces/ButtonUnitShape";
import {useAuthStore} from "../../Stores/Auth/AuthStore";
import {UserInterface} from "../../Interfaces/UserInterface";
import {useSiteCheckStore} from "../../Stores/SiteCheck/SiteCheck";
import Cookie from "js-cookie";
import AdvertApplyMixin from "../../Mixins/AdvertApplyMixin";
import {SiteCheckInterface} from "../../Stores/SiteCheck/Interfaces/SiteCheckInterface";

export default defineComponent({
	name: "LayoutHeader",
	mixins: [ScreenWidthChecker, AdvertApplyMixin],
	inject: ['emitter'],
	components: {ProfileSidebarLinks, LanguageSelect, ButtonUnit, LayoutHeaderLink},
	setup() {
		return {
			authStore: useAuthStore(),
			siteStore: useSiteCheckStore(),
		}
	},
	data() {
		return {
			isLoading: false,
			headerBreakpoint: 'lg',
			headerBreakpointValue: Breakpoint.LG,
			isLinksOpened: false,
		}
	},
	mounted() {
		this.linkOpenModel();
		this.isLinksOpened = window.innerWidth >= this.headerBreakpointValue;
	},
	watch: {
		isScreenUnderLg() {
			this.isLinksOpened = !this.isScreenUnderLg;
		},
		currentUser() {
			if (this.currentUser.hasOwnProperty('email') && this.$route?.query.hasOwnProperty('advertId')) {
				this.openApplyModal(Number(this.$route.query.advertId));
			}
		},
		"$route.name"() {
			window.scrollTo({top: 0});
			if (window.innerWidth <= this.headerBreakpointValue) {
				this.isLinksOpened = false;
			}
		}
	},
	computed: {
		bgColor(): string {
			return `var(--${LayoutCssVariables.header.bgColor})`;
		},
		textColor(): string {
			return LayoutCssVariables.header.textColor;
		},
		textColorVar(): string {
			return `var(--${LayoutCssVariables.header.textColor})`;
		},
		buttonShape(): string | ButtonUnitShape {
			// TODO
			// return LayoutCssVariables.header.buttonShape;
			return ButtonUnitShape.ROUNDED
		},
		buttonBgColor(): string {
			return `var(--${LayoutCssVariables.header.buttonBgColor})`;
		},
		buttonTextColor(): string {
			return `var(--${LayoutCssVariables.header.buttonTextColor})`;
		},
		currentUser(): UserInterface {
			return this.authStore.currentUser;
		},
		isLoaded(): boolean {
			return this.authStore.isLoggedIn;
		},
		currentSite(): SiteCheckInterface {
			return this.siteStore.currentSite;
		}
	},
	methods: {
		toggleMenuLinks(checkForChildren: boolean = false): void {
			if (window.innerWidth <= this.headerBreakpointValue && !checkForChildren) {
				document.querySelector('html')?.classList.toggle('overflow-hidden');
				document.body.classList.toggle('overflow-hidden');
				this.isLinksOpened = !this.isLinksOpened;
			}
		},
		openModal(): void {
			// @ts-ignore
			this.emitter.emit('onModalShow_login-registration-modal');
		},
		openApplyModal(indexId: number): void {
			// @ts-ignore
			this.apply(indexId);
		},
		logout(): void {
			this.authStore.logout();
			this.$router.push({name: 'home'});
		},
		linkOpenModel(): void {
			let searchParams = new URLSearchParams(window.location.search)
			if (!this.isLoaded && (searchParams.has('show-modal') && (searchParams.get('show-modal') === 'register' || searchParams.get('show-modal') === 'login'))) {
				setTimeout(() => {
					this.openModal();
				}, 1000);
			}
		}
	},
});
</script>

<style scoped>
.layout-header__container {
	background-color: v-bind(bgColor);
}

.layout-header__list {
	background-color: v-bind(bgColor);
}

.layout-header__menu-btn {
	color: v-bind(textColorVar);
}

.button-unit.bordered {
	border-color: v-bind(buttonTextColor);
}

@media (min-width: 992px) {
	.layout-header__list {
		background-color: transparent;
	}
}
</style>
