<template>
	<v-modal :name="this.modalId" :header="translate('where-search-adverts')" :maxWidth="'1000px'" :passed-data="(passedData) => this.onModalPassedData(passedData)">
		<template #body>
			<div class="row gap-4">
				<div class="row">
					<vue-select-unit
							v-model="this.dropdownSelected"
							:options="{
								slug: 'location_input',
								label: 'name',
								class: 'search-bar-select-input p-0',
								placeholder: this.translate('location-input-placeholder'),
								multiple: false,
								taggable: false,
								clearable: true,
								getOptionKey: (option) => {
									return option.probond_id;
								},
								resetOnOptionsChange:true,
								selectable: (option) => !option.hasOwnProperty('selectable') || option.selectable,
								axiosSearchUrl: 'api/locations-search',
								axiosSearchKeyword: 'keyword',
								axiosSearchDataMapper: (response) => {
									let returnData = [];
									if (Object.keys(response.data.cities).length > 0){
										returnData = ([...returnData, {id: 1, name: this.translate('general-cities'), selectable: false}, ...Object.values(response.data.cities)])
									}
									if (Object.keys(response.data.countries).length > 0){
										returnData = ([...returnData, {name: this.translate('general-countries'), selectable: false}, ...Object.values(response.data.countries)])
									}
									if (Object.keys(response.data.counties).length > 0){
										returnData = ([...returnData, {name: this.translate('general-counties'), selectable: false}, ...Object.values(response.data.counties)])
									}

									return returnData;
								}
							}"
					/>
				</div>
				<div class="row gap-2">
					<div class="col-auto"
						 v-for="item in searchData"
					>
						<advert-list-selected-search-item-button
								:item="item"
								:callback="this.removeItem"
						/>
					</div>
				</div>
				<div class="row d-flex flex-column flex-md-row" v-if="Object.keys(districts).length > 0 || Object.keys(counties).length > 0 || Object.keys(countySeats).length > 0">
					<div class="d-flex flex-column flex-md-row location-search-modal__tab py-4 gap-4">
						<div
								v-if="Object.keys(counties).length > 0 || Object.keys(countySeats).length > 0"
								:class="{
								'col-12 col-md-auto px-3': true,
								'location-search-modal__tab-selected': this.selectedTab === 2
							}"
								@click="() => this.setTabIndex(2)"
						>
							<span>{{ translate('general-counties') }}</span>
						</div>
						<div v-if="Object.keys(districts).length > 0" :class="{
								'col-12 col-md-auto px-3': true,
								'location-search-modal__tab-selected': this.selectedTab === 1
							}"
							 @click="() => this.setTabIndex(1)"
						>
							<span>{{ translate('budapest') }}</span>
						</div>
						<div :class="{
								'col-12 col-md-auto px-3': true,
								'location-search-modal__tab-selected': this.selectedTab === 3
							}"
							 @click="() => this.setTabIndex(3)"
						>
							<span>{{ translate('aboard-location') }}</span>
						</div>
					</div>
				</div>
				<div class="row" v-if="selectedTab === 1">
					<h5 class="mb-3">{{ translate('districts') }}</h5>
					<div class="col-12 col-md-4 col-lg-3 p-1" v-for="(child,index) in districts">
						<div class="form-check">
							<input class="form-check-input"
								   type="checkbox"
								   v-model="searchData"
								   :value="child"
								   :id="'districts_'+index"
							/>
							<label class="form-check-label" :for="'districts_'+index">{{ child.name }}</label>
						</div>
					</div>
				</div>
				<div class="row gap-4" v-if="selectedTab === 2">
					<div class="row" v-if="Object.keys(counties).length > 0">
						<h5 class="mb-3">{{ translate('general-counties') }}</h5>
						<div class="col-12 col-md-4 col-lg-3 p-1" v-for="(child,index) in counties">
							<div class="form-check">
								<input class="form-check-input"
									   type="checkbox"
									   v-model="searchData"
									   :value="child"
									   :id="'general-counties_'+index"
								/>
								<label class="form-check-label" :for="'general-counties_'+index">{{ child.name }}</label>
							</div>
						</div>
					</div>

					<div class="row" v-if="Object.keys(countySeats).length > 0">
						<h5 class="mb-3">{{ translate('county-seats') }}</h5>
						<div class="col-12 col-md-4 col-lg-3 p-1"
							 v-for="(child,index) in countySeats"
						>
							<div class="form-check">
								<input class="form-check-input"
									   type="checkbox"
									   v-model="searchData"
									   :value="child"
									   :id="'county-seats_'+index"
								/>
								<label class="form-check-label" :for="'county-seats_'+index">{{ child.name }}</label>
							</div>
						</div>
					</div>
				</div>
				<div class="row gap-4" v-if="selectedTab === 3">
					<h5 class="mb-3">{{ translate('aboard-location') }}</h5>
					<div class="col-12 col-md-4 col-lg-3 p-1" v-for="(child,index) in filteredAboard">
						<div class="form-check">
							<input
									:id="'aboard_'+index"
									class="form-check-input"
									type="checkbox"
									v-model="searchData"
									:value="child"
							/>
							<label :for="'aboard_'+index" class="form-check-label">{{ child.name }}</label>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-12 col-md-auto">
						<button-unit
								:options="{
									text: translate('filter'),
									shape: ButtonUnitShape.ROUNDED,
									textColor: 'white',
									bgColor: '',
								}"
								@click-on-btn="this.onFilter"
						/>
					</div>
				</div>
			</div>
		</template>
	</v-modal>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import VModal from "../VModal.vue";
import InputUnit from "../Units/InputUnit.vue";
import CheckboxUnit from "../Units/CheckboxUnit.vue";
import ButtonUnit from "../Units/ButtonUnit.vue";
import {ButtonUnitShape} from "../Units/Interfaces/ButtonUnitShape";
import VueSelectUnit from "../Units/VueSelectUnit.vue";
import AdvertListSelectedSearchItemButton from "../../Views/AdvertListView/Components/AdvertListSelectedSearchItemButton.vue";
import searchDataAPI from "../../API/searchData.api";
import {IAdvertCounty} from "../../Interfaces/Advert/IAdvertCounty";
import {IAdvertDistrict} from "../../Interfaces/Advert/IAdvertDistrict";
import {IAdvertTownship} from "../../Interfaces/Advert/IAdvertTownship";
import {ISearchDefaultItems} from "../../Interfaces/Search/ISearchDefaultItems";
import {LocationListItemInterface} from "../Interfaces/LocationListItemInterface";
import {ResponseItemList} from "../../Utils/types";
import {IAdvertCountry} from "@/WebApp/Interfaces/Advert/IAdvertCountry";
import {useSearchDataStore} from "@WebApp/Stores/SearchData/SearchDataStore";
import {useSiteCheckStore} from "../../Stores/SiteCheck/SiteCheck";

export default defineComponent({
	name: "AdvertSearchLocationModal",
	inject: ['emitter'],
	components: {VueSelectUnit, ButtonUnit, InputUnit, VModal, CheckboxUnit, AdvertListSelectedSearchItemButton},
	setup() {
		return {
			searchDataStore: useSearchDataStore(),
			siteStore: useSiteCheckStore()
		}
	},
	data() {
		return {
			modalId: 'advert-search-location-modal',
			searchData: [] as LocationListItemInterface[],
			selectedTab: 2,
			dropdownSelected: null as LocationListItemInterface,
		}
	},
	computed: {
		ButtonUnitShape() {
			return ButtonUnitShape;
		},
		counties(): IAdvertCounty[] | undefined {
			return this.searchDataStore.counties;
		},
		districts(): IAdvertDistrict[] | undefined {
			return this.searchDataStore.bpDistricts;
		},
		countySeats(): IAdvertTownship[] | undefined {
			return this.searchDataStore.countySeats;
		},
		countries(): IAdvertCountry[] | undefined {
			return this.searchDataStore.countries;
		},
		filteredAboard(): IAdvertCounty[] {
			if (this.countries !== undefined) {
				return this.countries?.filter((element: IAdvertCounty) => !this.siteStore.currentSite.default_country.split(',').includes(element.name));
			}
			return [];
		}
	},
	watch: {
		dropdownSelected() {
			if (this.dropdownSelected && !this.dropdownSelected.hasOwnProperty('selectable') && this.searchData.findIndex((element: LocationListItemInterface) => element.probond_id === this.dropdownSelected.probond_id) === -1) {
				this.searchData = [...this.searchData, this.dropdownSelected];
				this.dropdownSelected = null;
			}
		},
	},
	methods: {
		closeModal: () => {
			// @ts-ignore
			this.emitter.emit('onModalClose_' + this.modalId);
		},
		setTabIndex(tabIndex: any): void {
			this.selectedTab = tabIndex;
		},
		onFilter(): void {
			this.emitter.emit('onAdvertLocationSearch', this.searchData);
		},
		onModalPassedData(passedData): void {
			this.searchData = [...passedData];
		},
		removeItem(item: LocationListItemInterface): void {
			const index = this.searchData.findIndex((element: LocationListItemInterface) => element.probond_id === item.probond_id);
			if (index > -1) {
				this.searchData.splice(index, 1);
			}
		},
	},
});
</script>

<style scoped>
.button-unit {
	background: var(--color-profile);
}

.location-search-modal__tab {
	background-color: #f2f5f4;
	cursor: pointer;
}

.location-search-modal__tab-selected {
	color: var(--color-profile);
	font-weight: bold;
}
</style>
